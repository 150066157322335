import { BroadcastChannel } from "broadcast-channel";
import { batch } from "react-redux";

import { startAppListening } from "./listenerMiddleware";

const buildBroadcastMiddleware = (channel: BroadcastChannel) => {
  startAppListening({
    predicate: (action, _currentState, _previousState) =>
      action.type === "broadcast",
    effect: async (action, listenerApi) => {
      channel.postMessage(action.payload);
      batch(() => {
        for (action of action.payload) {
          listenerApi.dispatch(action);
        }
      });
    },
  });
};

export default buildBroadcastMiddleware;
