import Scaler from "./Scaler";

import * as styles from "./Splash.module.css";

const Splash: React.FC = ({ children }) => (
  <div className={styles.outer}>
    <Scaler>
      <div className={styles.container}>{children}</div>
    </Scaler>
  </div>
);

export default Splash;
