import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const ALL_DECKS = [
  "overview",
  "intro",
  "ownership",
  "option-and-result",
  "error-handling",
  "iterators",
  "types",
  "concurrency",
  "async",
  "from-dynamic-languages",
  "reliable-code-using-types",
  "testing",
  "strings-vs-bytes",
  "macros",
  "ffi",
  "embedded",
] as const;
type DeckTuple = typeof ALL_DECKS;
export type Deck = DeckTuple[number];

const DEFAULT_DECK: Deck = "overview";

const isValidDeckType = (s: string): s is Deck => ALL_DECKS.includes(s as Deck);

export const validateDeckType = (s: string | null): Deck =>
  s && isValidDeckType(s) ? s : DEFAULT_DECK;

export interface RouteState {
  deck: Deck;
}

const initialState: RouteState = {
  deck: DEFAULT_DECK,
};

export const routeSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    setDeck: (state, action: PayloadAction<Deck>) => {
      state.deck = action.payload;
    },
  },
});

export const { setDeck } = routeSlice.actions;

export default routeSlice.reducer;
