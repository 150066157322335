import Transclude, { TranscludeProps } from "./Transclude";
import React from "react";

type ExcerptProps = Pick<TranscludeProps, "emphasize">;
const excerpt = (
  src: TranscludeProps["src"],
  focusOn: TranscludeProps["focusOn"],
  extra: {
    transform?: TranscludeProps["transform"];
    insert?: TranscludeProps["insert"];
  } = {}
) => {
  const Excerpt: React.FC<ExcerptProps> = ({ emphasize }) => (
    <Transclude
      src={src}
      focusOn={focusOn}
      emphasize={emphasize}
      transform={extra.transform}
      insert={extra.insert}
    />
  );
  return Excerpt;
};

// ----------

import allocString from "rust:extracted/alloc/src/string.rs";

export const String = excerpt(allocString, "366+3");
export const StringFromUtf8 = excerpt(allocString, "431,571,576,1850", {
  insert: { 3: "        // ..." },
});

// ----------

import coreFutureFuture from "rust:extracted/core/src/future/future.rs";

export const FutureTrait = excerpt(coreFutureFuture, "37,40+2,104+2");

// ----------

import coreIterTraitsIterator from "rust:extracted/core/src/iter/traits/iterator.rs";

export const IteratorTrait = excerpt(
  coreIterTraitsIterator,
  "67,70-71,104,3818"
);

export const IteratorByRef = excerpt(coreIterTraitsIterator, "67,1714+3,3818");
export const IteratorCollect = excerpt(
  coreIterTraitsIterator,
  "67,1832+3,3818"
);
export const IteratorCycle = excerpt(coreIterTraitsIterator, "67,3312+3,3818");
export const IteratorFilter = excerpt(coreIterTraitsIterator, "67,896+4,3818");
export const IteratorMap = excerpt(coreIterTraitsIterator, "67,776+4,3818");
export const IteratorRev = excerpt(coreIterTraitsIterator, "67,3161+3,3818");
export const IteratorRposition = excerpt(
  coreIterTraitsIterator,
  "67,2920+4,3818"
);

// ----------

import coreIterTraitsCollect from "rust:extracted/core/src/iter/traits/collect.rs";

export const IntoIteratorTrait = excerpt(
  coreIterTraitsCollect,
  "231,234,238-239,261-262"
);

// ----------

import coreOption from "rust:extracted/core/src/option.rs";

export const OptionEnum = excerpt(coreOption, "518,522,526+2", {
  transform: { 3: [/#\[.*\] /, ""] },
});

// ----------

import coreSliceMod from "rust:extracted/core/src/slice/mod.rs";

export const SliceSplitAtMut = excerpt(coreSliceMod, "1576+6");
export const SliceSplitAtMutUnchecked = excerpt(coreSliceMod, "1669+13");

// ----------

import coreSliceRaw from "rust:extracted/core/src/slice/raw.rs";

export const SliceFromRawParts = excerpt(coreSliceRaw, "90", {
  insert: { 2: "    // ...\n}" },
});
export const SliceFromRawPartsMut = excerpt(coreSliceRaw, "134,143", {
  insert: { 2: "    // ..." },
});

// ----------

import coreStrConverts from "rust:extracted/core/src/str/converts.rs";

export const StrFromUtf8 = excerpt(coreStrConverts, "87,96", {
  insert: { 2: "    // ..." },
});

// ----------

import coreStrIter from "rust:extracted/core/src/str/iter.rs";

export const CharsIterator = excerpt(coreStrIter, "91,112,116");

// ----------

import coreTaskPoll from "rust:extracted/core/src/task/poll.rs";

// TODO remove those attributes
export const PollEnum = excerpt(coreTaskPoll, "13,17,26+2", {
  transform: { 2: [/#\[.*\] /, ""] },
});

// ----------

import stdThreadMod from "rust:extracted/std/src/thread/mod.rs";

export const ThreadSpawn = excerpt(stdThreadMod, "650-654");

import stdThreadScoped from "rust:extracted/std/src/thread/scoped.rs";

export const ThreadScopedScope = excerpt(stdThreadScoped, "129-131");
export const ThreadScopedSpawn = excerpt(stdThreadScoped, "163,189-192,196");

// ----------

import futuresCoreFuture from "rust:extracted/futures-core-0.3.25/src/future.rs";

export const TryFutureTrait = excerpt(futuresCoreFuture, "58,60,63+2,70+2");

// ----------

import futuresCoreStream from "rust:extracted/futures-core-0.3.25/src/stream.rs";

export const StreamTrait = excerpt(futuresCoreStream, "27,29+2,66,98");
export const TryStreamTrait = excerpt(futuresCoreStream, "167,169,172+2,179+5");

// ----------

import futuresIo from "rust:extracted/futures-io-0.3.25/src/lib.rs";

export const FuturesIoAsyncReadPollRead = excerpt(futuresIo, "60+5");

// ----------

import futuresUtilIoMod from "rust:extracted/futures-util-0.3.25/src/io/mod.rs";

export const FuturesAsyncReadExtRead = excerpt(futuresUtilIoMod, "204+3");

// ----------

import futuresUtilFutureFutureMod from "rust:extracted/futures-util-0.3.25/src/future/future/mod.rs";

export const FutureExtTrait = excerpt(futuresUtilFutureFutureMod, "123,610", {
  insert: { 2: "    // 19 methods" },
});

// ----------

import futuresUtilFutureTryFutureMod from "rust:extracted/futures-util-0.3.25/src/future/try_future/mod.rs";

export const TryFutureExtTrait = excerpt(
  futuresUtilFutureTryFutureMod,
  "137,625",
  {
    insert: { 2: "    // 19 methods" },
  }
);

// ----------

import futuresUtilStreamStreamMod from "rust:extracted/futures-util-0.3.25/src/stream/stream/mod.rs";

export const StreamExtTrait = excerpt(futuresUtilStreamStreamMod, "249,1685", {
  insert: { 2: "    // 46 methods" },
});

// ----------

import futuresUtilStreamTryStreamMod from "rust:extracted/futures-util-0.3.25/src/stream/try_stream/mod.rs";

export const TryStreamExtTrait = excerpt(
  futuresUtilStreamTryStreamMod,
  "157,1064",
  {
    insert: { 2: "    // 25 methods" },
  }
);

// ----------

import synDerive from "rust:extracted/syn-2.0.28/src/derive.rs";

export const DeriveInput = excerpt(synDerive, "7-13");
export const Data = excerpt(synDerive, "25-29");
export const DataStruct = excerpt(synDerive, "35-39");

// ----------

import tokioIoAsyncRead from "rust:extracted/tokio-1.22.0/src/io/async_read.rs";

export const TokioAsyncReadPollRead = excerpt(tokioIoAsyncRead, "53+5");

// ----------

import tokioIoUtilAsyncReadExt from "rust:extracted/tokio-1.22.0/src/io/util/async_read_ext.rs";

export const TokioAsyncReadExtRead = excerpt(tokioIoUtilAsyncReadExt, "168+3");
