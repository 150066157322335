import { Notes, Continue, SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import Splash from "./layout/Splash";
import TestPattern from "./TestPattern";
import Transclude from "./Transclude";

import * as styles from "./intro.module.css";

<SlideLayout use={TestPattern} />

---

<SlideLayout use={Splash} />

# Hands-on introduction to Rust

---

# Agenda

1. Rustup and Cargo
1. Basics and documentation
1. Iterating
1. Making our own types
1. Strings and user input
1. Modules

---

import integer32Logo from "./assets/intro/integer32.png";

<SlideLayout use={Splash} />

<img className={styles.fullImage} src={integer32Logo} alt="i32 logo" />

<Notes />

- Cofounder

---

import kirbyLogo from "./assets/intro/kirby.png";

<SlideLayout use={Splash} />

# Stack Overflow

<img className={styles.fullImage} src={kirbyLogo} alt="gravatar" />

<Notes />

- \#1 answerer on Stack Overflow for Rust tag

---

import playgroundScreenshot from "./assets/intro/playground.png";

<SlideLayout use={Splash} />

# Rust Playground

<img
  className={styles.fullImage}
  src={playgroundScreenshot}
  alt="playground screenshot"
/>

### [play.rust-lang.org](https://play.rust-lang.org)

<Notes />

- maintainer

---

# Jake Goulding

- Rust infrastructure team
- Collaborated on a Rust video course for Manning
- A handful of crates
- Help out with AVR-Rust

<Notes />

- C background
- crates: error-handling, XML, parsing, low-level assembly

---

<SlideLayout use={Splash} />

# Who are you?

<Notes />

- Who is a programmer?
- What languages?
- Used Rust at all?
- Why Rust?

---

# Rustup

- Toolchain manager
  - Installs Rust
  - Keeps it updated
  - Allows multiple concurrent versions

---

# Basic usage

## Installation

https://rustup.rs/

## Upgrading

`rustup update`

---

# Cargo

- Package manager
- Build tool
  - Code
  - Tests
  - Docs

---

# Cargo

`cargo new --bin name-of-crate`

<Notes />

- Have students list the directory

<Continue />

```compiler-output
.
├── Cargo.toml
└── src
    └── main.rs
```

<Notes />

- `--bin` is the default
- Can also make reusable libraries with `--lib`

---

# Cargo

`cargo run`

<Notes />

- Have students list the directory

<Continue />

```compiler-output
.
├── Cargo.lock
└── target
    └── debug
```

---

import helloWorld from "rust:intro/hello-world";

# Hello, world!

<Transclude src={helloWorld} />

<Notes />

- `fn` keyword
- `main` is entry point for an executable
- parenthesis
- curly braces
- 4 space indent
- println is a macro, don't worry too much about that
- semicolons

---

import printingOutput from "rust:intro/printing-output";

# Printing values

<Transclude src={printingOutput} />

<Notes />

- `{}` prints something intended for an end-user
- `{:?}` prints something for a developer
- Only single ident is valid for the inline format
- Can also print to stderr
- Rust does work with debuggers, but print debugging is so common

---

import comments from "rust:intro/comments";

# Comments

<Transclude src={comments} />

---

# API Documentation

- https://doc.rust-lang.org/
  - "The Standard Library"
- `rustup doc`
  - `rustup doc --std`

---

import functionsRev0 from "rust:intro/functions";
import functionsRev1 from "rust:intro/functions?rev=1";

# Functions

<Transclude src={functionsRev0} focusOn="1+5" />

<Notes />

- statement has a semicolon at the end
- expression doesn't
- a block can have many statements, last may be expression
- final expression of a block is the value of the block
- Return type is optional

<Continue />

<Transclude src={functionsRev1} focusOn="1+3" />

<Notes />

- Can use `return` to exit from a function early

---

import variablesRev0 from "rust:intro/variables";

# Variables

<Transclude src={variablesRev0} focusOn="2+3" />

<Notes />

- keyword `let`
- every variable has a type
- type is inferred

---

import variablesRev1 from "rust:intro/variables?rev=1";
import variablesRev1Error from "stderr:intro/variables?rev=1";

# Variables

<Transclude src={variablesRev1} />

<Notes />

- Try this

<Continue />

<Transclude src={variablesRev1Error} lang="compiler-error" focusOn="10+10" />

---

import variablesRev2 from "rust:intro/variables?rev=2";

# Variables are immutable by default

<Transclude src={variablesRev2} emphasize="2[8+3]" />

<Notes />

- Lots of functional languages do this
- Reduces places to reason about accidental changes
- Ever passed a collection to a function and it modified it?
- Default encourages usage
- Possible for the compiler to make some optimizations

---

# Built-in types

- `u32`: unsigned 32-bit integer
- `i32`: signed 32-bit integer
- `f64`: floating point number
- `String` and/or `&str`: more on these later
- `bool`: a boolean
- `(T1, T2, ...)`: a tuple

<Notes />

- Different sizes of numeric types (`i32`, `u8`, etc.)
- `Vec` / `[T]` / `[T; N]`

---

# Type inference / explicit types

- Most of the time, you don't need to specify the type
- You can choose to if it helps you learn

```rust
let name: Type = value;
```

<Notes />

- Can use the empty tuple to get a compile error with the real type

---

import conditionals from "rust:intro/conditionals";

# `if`

<Transclude src={conditionals} focusOn="7+13" />

<Notes />

- No parenthesis on condition
- Evaluates to value of chosen block

---

# `match`

<Transclude src={conditionals} focusOn="26+9" />

<Notes />

- can have multiple patterns
- first that matches is applied
- can have single expression or curly braces
- catch-all pattern of `_`

---

# Exercise

- Create a `fibonacci` function
  - `F(n) = F(n-1) + F(n-2)`
  - `F(0) = 0`
  - `F(1) = 1`
- Print out the result of calling the function with `10`

---

import fibonacciRev0 from "rust:intro/fibonacci";

# One answer

<Continue />

<Transclude src={fibonacciRev0} />

---

import fibonacciRev1 from "rust:intro/fibonacci?rev=1";

# Another answer

<Transclude src={fibonacciRev1} />

---

import vectorIteration from "rust:intro/vector-iteration";

# Vectors

<Transclude src={vectorIteration} focusOn="2+4" />

<Notes />

- Declare using the `vec!` macro
- Access an item with `[]`
- Lots of methods available; check API for `Vec`
- Rust also has arrays, but they are fairly limited compared to `Vec`

---

# `for`

<Transclude src={vectorIteration} focusOn="11+3" />

<Notes />

- `expression` can be anything that creates an iterator

<Continue />

Expressions can be things like:

- `0..10` or `0..=9`
- `vector.iter()`
- `&vector`

---

# Iterating

<Transclude src={vectorIteration} focusOn="17+5" />

<Continue />

<Transclude src={vectorIteration} focusOn="23+3" />

<Notes />

- Have students try to do the loop twice.

---

import vectorIterationError from "stderr:intro/vector-iteration";

# Iterating

<Transclude src={vectorIterationError} lang="compiler-error" focusOn="1+23" />

---

# Iterating

import vectorIterationRev1 from "rust:intro/vector-iteration?rev=1";

<Transclude src={vectorIterationRev1} focusOn="17+9" emphasize="3[13+7]" />

<Notes />

- Iterating over `scores` takes ownership, `&scores` does not

---

import iterators from "rust:intro/iterators";

# Iterators

<Transclude src={iterators} focusOn="2+3" />

<Notes />

- Iterator adapters like `map` or `filter` produce new iterators
- Check the docs for `Iterator`

<Continue />

<Transclude src={iterators} focusOn="6+3" />

<Continue />

<Transclude src={iterators} focusOn="10+3" />

---

# Iterators

<Transclude src={iterators} focusOn="14" />

<Notes />

- Iterator consumers convert to something else

<Continue />

<Transclude src={iterators} focusOn="16" />

---

# Exercise

- Print out the values from 0 (inclusive) to 100 (exclusive)
- That are divisible by 3
- And divisible by 7
- 0, 21, ...

## Bonus

- Instead of printing them out, try adding them up
- Print them out **and** add them up in one iteration

---

import iteratorExampleRev0 from "rust:intro/iterator-example";

# One answer

<Continue />

<Transclude src={iteratorExampleRev0} />

---

import iteratorExampleRev1 from "rust:intro/iterator-example?rev=1";

# Another answer

<Transclude src={iteratorExampleRev1} />

---

import structs from "rust:intro/structs";

# Structs

<Transclude src={structs} focusOn="5+3" />

<Notes />

- structs allow us to group related data together

<Continue />

<Transclude src={structs} focusOn="9+7" />

<Notes />

- if variable and field names match, can use shorthand

---

# Tuple structs

<Transclude src={structs} focusOn="19+1" />

<Continue />

<Transclude src={structs} focusOn="21+3" />

---

# Unit structs

<Transclude src={structs} focusOn="27+1" />

<Continue />

<Transclude src={structs} focusOn="29+1" />

<Notes />

- May seem useless, but will be useful when we talk about traits

---

import enums from "rust:intro/enums";

# Enums

<Transclude src={enums} focusOn="5+5" />

<Notes />

- enums allow us to model mutually exclusive choices, known as _variants_
- some languages might call this a "discriminated union"

<Continue />

<Transclude src={enums} focusOn="11+11" />

<Notes />

- often used with a `match` statement

---

import unions from "rust:intro/unions";

# Unions

<Transclude src={unions} focusOn="5+4" />

<Notes />

- Very rarely seen.
- Mostly used for C FFI and weird tricks

<Continue />

<Transclude src={unions} focusOn="10+2" />

<Notes />

- Accessing a union's field is not allowed in safe Rust

---

import deriveDebug from "rust:intro/derive-debug";

# `#[derive(Debug)]`

- Add this to every type you create:

  <Transclude src={deriveDebug} focusOn="1+5" emphasize="1,4" />

- This will allow you to print a value with `{:?}`

---

# Exercise

- Create `Fahrenheit` and `Celsius` structs
- Create a function that converts `Fahrenheit` to `Celsius`
- `C = (F - 32.0) / 1.8`

## Bonus

- Instead of structs, do it with a single enum

---

# One answer

import temperatureFunction from "rust:intro/temperature-function";

<Continue />

<Transclude src={temperatureFunction} />

<Notes />

- ownership of temperature is taken
- derive `Copy`?

---

import methods from "rust:intro/methods";

# Methods

<Transclude src={methods} focusOn="6+5" emphasize="2[16+5]" />

<Notes />

- methods allow us to associate functions with a specific set of data (struct or enum)
- name `self` is special

<Continue />

<Transclude src={methods} focusOn="12+5" emphasize="2[16+9]" />

<Continue />

<Transclude src={methods} focusOn="18+5" emphasize="2[16+4]" />

---

# Method calls

<Transclude src={methods} focusOn="24+3" />

<Notes />

- `self` argument is implicit

<Continue />

<Transclude src={methods} focusOn="28+3" />

<Continue />

<Transclude src={methods} focusOn="32+3" />

---

# Exercise

- Create a method that converts `Celsius` to `Fahrenheit`
- `F = C * 1.8 + 32.0`

---

import temperatureMethod from "rust:intro/temperature-method";

# One answer

<Continue />

<Transclude src={temperatureMethod} />

---

# Strings

- Rust has two primary string types:
- `String`
  - Owns the data
  - Can be extended or reduced
- `&str`
  - References existing data
  - Cannot change length

---

import strings from "rust:intro/strings";

# Strings

- Can convert from a `&str` to a `String` via `to_string()`

  <Transclude src={strings} focusOn="2" />

- Can get a `&str` from a `String` via `as_str()`

  <Transclude src={strings} focusOn="3" />

<Notes />

- Can use `to_string` to convert lots of types to a String, actually

---

# Exercise

- Create a function that accepts a number and prints it
- Multiples of three print “Fizz” instead of the number
- Multiples of five print “Buzz” instead of the number
- Multiples of both three and five print “FizzBuzz”
- Call the function with the numbers from 1 to 100

## Bonus

- Change the function to return a string instead of printing

---

import fizzBuzz from "rust:intro/fizz-buzz";
import fizzBuzzOut from "stdout:intro/fizz-buzz";

# One answer

<Continue />

<Transclude src={fizzBuzz} />

<Transclude src={fizzBuzzOut} lang="compiler-output" focusOn="1+15" />

<Notes />

- https://chrismorgan.info/blog/rust-fizzbuzz/

---

import inputStdin from "rust:intro/input-stdin";

# Reading user input

<Transclude src={inputStdin} focus="" />

<Notes />

- Warning: the string has the trailing newline from hitting enter!
- Use `trim` to remove it.
- `expect` is for error handling; will talk more later

---

import inputFile from "rust:intro/input-file";

# Reading from files

<Transclude src={inputFile} />

<Notes />

- There's also `fs::read` which just gets the bytes
- This is the easy function, if you need more control it's available

---

import outputFile from "rust:intro/output-file";

# Writing to files

<Transclude src={outputFile} />

<Notes />

- This is the easy function, if you need more control it's available

---

import stringParsing from "rust:intro/string-parsing";

# Parsing strings

## With a type on the variable

<Transclude src={stringParsing} focusOn="2" />

<Notes />

- Can parse to multiple types, so we need to specify what we want.

<Continue />

## With a type on the function call

<Transclude src={stringParsing} focusOn="3" />

<Notes />

- Turbofish specifies what target type
- https://turbo.fish

---

# Exercise

- Read user input of a temperature and convert it
- `C = (F - 32.0) / 1.8`
- `F = C * 1.8 + 32.0`

## Bonus

- Ask if it's Celsius or Fahrenheit
- Read a file of temperatures, convert, write to new file

---

import temperatureInput from "rust:intro/temperature-input";

# One answer (1/2)

<Continue />

<Transclude src={temperatureInput} focusOn="1+17" />

---

# One answer (2/2)

<Transclude src={temperatureInput} focusOn="19+26" />

<!-- Error handling moved to separate slides -->

---

import modules from "rust:intro/modules";
import modulesErr from "stderr:intro/modules";

# Modules

<Transclude src={modules} />

<Notes />

- `mod` keyword

<Continue />

<Transclude src={modulesErr} lang="compiler-error" focusOn="1+11" />

---

import visibility from "rust:intro/visibility";

# Visibility

<Transclude src={visibility} />

<Notes />

- `pub` basically means "the thing that contains me can see this"
- There's some other nuanced visibility, but we don't need to worry about it for now

---

import childVisibility from "rust:intro/child-visibility";

# Child modules can access everything from ancestors

<Transclude src={childVisibility} focusOn="1+13" />

---

import moduleImport from "rust:intro/module-import";

# Importing items from other modules

<Transclude src={moduleImport} />

---

import moduleImportRev1 from "rust:intro/module-import?rev=1";

# Importing items from other modules

<Transclude src={moduleImportRev1} emphasize="11,14" />

---

# Import paths

### Child

```rust
use module_name::thing;
```

### Parent

```rust
use super::thing;
```

### Crate root

```rust
use crate::thing;
```

<Notes />

- There are other less common ones, like `self`

---

# Import selectors

### Single

```rust
use foo::Bar;
```

### Multiple

```rust
use foo::{Bar, Baz};
```

### Glob

```rust
use foo::*;
```

## Renaming

```rust
use foo::Bar as MyName;
```

<Notes />

- Can be combined and nested, where it makes sense

---

# Modules in files

**src/main.rs**

```rust
mod example {
    pub fn in_module() {
        println!("I'm inside a module");
    }
}
```

<Notes />

- This seems to be a complicated aspect for many people.

---

# Modules in files

**src/main.rs**

```rust
mod example;
```

**src/example.rs**

```rust
pub fn in_module() {
    println!("I'm inside a module");
}
```

<Notes />

- No need to put `mod` in **example.rs**

---

# Deeply nested modules (Modern)

```compiler-output
src
├── main.rs
├── example.rs
└── example
    └── nested.rs
```

`src/main.rs`

```rust
mod example;

fn main() {
    example::nested::foo();
}
```

`src/example.rs`

```rust
pub mod nested;
```

`src/example/nested.rs`

```rust
pub fn foo() {
    println!("Hello, world!");
}
```

---

# Deeply nested modules (Legacy)

<Transclude lang="compiler-output" emphasize="4[8+6]">{`src
├── main.rs
└── example
    ├── mod.rs
    └── nested.rs
`}</Transclude>

`src/main.rs`

```rust
mod example;

fn main() {
    example::nested::foo();
}
```

`src/example/mod.rs`

```rust
pub mod nested;
```

`src/example/nested.rs`

```rust
pub fn foo() {
    println!("Hello, world!");
}
```

---

# Exercise

- Create a function in a module which calls two others.
- Each function returns a number.
- The parent function should be called in `main`
- Code like this should run:

  ```rust
  fn main() {
      println!("{}", secret::code());
      // secret::key(); // Has an error!
      // secret::signature(); // Has an error!
  }
  ```

## Bonus

- Extract the module to a separate file

---

# One answer

<Continue />

```rust
fn main() {
    println!("{}", secret::code());
    // secret::key(); // Has an error!
    // secret::signature(); // Has an error!
}

mod secret {
    pub fn code() -> i32 {
        key() + signature()
    }

    fn key() -> i32 {
        1
    }

    fn signature() -> i32 {
        2
    }
}
```

<!-- Tests extracted to separate slides -->

---

# Rust's development

- Shipped 1.0 on 2015-05-15
- New stable release every 6 weeks
  - nightly builds
  - beta candidates
  - stable releases
- Current stable is 1.71.1
- Process designed to be as open as possible

---

# New feature genesis

- Start life in forums / chat servers
- An RFC is created
- Appropriate teams
  - debate and refine
  - approve or reject

---

# Feature implementation

- Working group forms
- Development is done
- Proposal to stabilize

---

# Prototyping

- Once a feature has reached some usable state, you can opt-in to using it
- Feature flags are available only in nightly Rust

---

# Editions

- "Stability without stagnation"
- Editions are completely compatible with each other
- Each crate decides what edition it wants

---

# Rust 2018

- Stabilized in Rust 1.31
- Reserves new keywords like `async` and `await`
- Changed some module / path behaviors
- Removed anonymous function parameters in traits

<Notes />

- Enabled NLL, but eventually enabled in Rust 2015 as well

---

# Rust 2021

- Stabilized in Rust 1.56
- Traits like `TryFrom` / `TryInto` added to the prelude
- `IntoIterator` fully implemented for arrays
- Closures now capture less aggressively
- Some warnings promoted to errors

---

<SlideLayout use={Splash} />

# <ChangeDeck deck="overview">Return</ChangeDeck>
