import Scaler from "./Scaler";

import * as styles from "./Default.module.css";
import { useAppSelector } from "../app/hooks";

const SlidePosition: React.FC = () => {
  const index = useAppSelector((state) => state.deck.slideIndex);
  const count = useAppSelector((state) => state.deck.slideCount);

  return (
    <span className={styles.pageNumber}>
      {index} / {count}
    </span>
  );
};

const Default: React.FC = ({ children }) => (
  <div className={styles.outer}>
    <Scaler>
      <div className={styles.container}>
        {children}
        <SlidePosition />
      </div>
    </Scaler>
  </div>
);

export default Default;
