import { Notes, Continue, SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import Splash from "./layout/Splash";
import TestPattern from "./TestPattern";
import Transclude from "./Transclude";

<SlideLayout use={TestPattern} />

---

<SlideLayout use={Splash} />

# `Option` and `Result`

---

# Optional values

- `Option` is an enum

  ```rust
  enum Option<T> {
      Some(T),
      None,
  }
  ```

<Notes />

- Generic type for presence case
- So prevalent that `Some` and `None` are imported by default
- This is why Rust does not have `NULL`

---

# Constructing an `Option`

import useOption from "rust:option-and-result/use-option";

<Transclude src={useOption} focusOn="1+7" />

---

# Constructing an `Option`

import useOptionRev1 from "rust:option-and-result/use-option?rev=1";

<Transclude
  src={useOptionRev1}
  focusOn="1+7"
  emphasize="1[34+7],1[53+1],3[13+5],3[24+1],4[13+5],4[23+1],5[13+4]"
/>

<Notes />

- Not obviously better than a `NULL`, yet.

---

# Using an `Option`

<Transclude src={useOptionRev1} focusOn="9+3" />

<Continue />

import useOptionRev1Stderr from "stderr:option-and-result/use-option?rev=1";

<Transclude lang="compiler-error" src={useOptionRev1Stderr} focusOn="1+13" />

<Notes />

- `Option<T>` is a different type from `T` with different methods
- Prevented from accessing the inner type without checking

---

# Using an `Option`

## Do something in both cases

import useOptionRev2 from "rust:option-and-result/use-option?rev=2";

<Transclude src={useOptionRev2} focusOn="9+6" />

<Continue />

## Do something in one case

import useOptionRev3 from "rust:option-and-result/use-option?rev=3";

<Transclude src={useOptionRev3} focusOn="9+5" />

---

# Success or failure values

- `Result` is an enum

  ```rust
  enum Result<T, E> {
      Ok(T),
      Err(E),
  }
  ```

<Notes />

- Generic types for success and failure cases
- So prevalent that `Ok` and `Err` are imported by default

---

# Constructing a `Result`

import useResult from "rust:option-and-result/use-result";

<Transclude src={useResult} focusOn="1+11" />

---

# Constructing a `Result`

import useResultRev1 from "rust:option-and-result/use-result?rev=1";

<Transclude
  src={useResultRev1}
  focusOn="1+11"
  emphasize="1[54+7],1[64+15],3,7,10[4+3],10[16+1]"
/>

---

# Using a `Result`

## Do something in both cases

<Transclude src={useResultRev1} focusOn="13+6" />

<Continue />

## Do something in one case

import useResultRev2 from "rust:option-and-result/use-result?rev=2";

<Transclude src={useResultRev2} focusOn="13+5" />

---

# Propagating errors

The `?` operator is syntax sugar for getting the success value or returning.

## `Option`

import propagate from "rust:option-and-result/propagate";

<Transclude src={propagate} focusOn="1+6" />

## `Result`

<Transclude src={propagate} focusOn="8+6" />

---

# Exercise: Error creation and propagation

- Write a function that adds two `i32` values
- If either of the values are greater than `10`, return an error
- If the sum is greater than `15`, return an error
- Call the function from main and prints a successful result

## Hints

- Write a helper function for the repeated logic and use `?`
- Use `()` as your returned error type and its value

---

# One answer

<Continue />

import exerciseCreateAndPropagate from "rust:option-and-result/exercise-create-and-propagate";

<Transclude src={exerciseCreateAndPropagate} />

---

# Why don't we use `Result` with a dummy error value?

## Semantics

- `Option` is the presence of data
- `Result` is only the outcome of an action

<Notes />

- `Option` can also be the outcome

---

# `Option` vs `Result`

## `Option`

- Field or function argument
- Retrieving values from collections
- "Null" pointers

## `Result`

- Return value of a function
- Annotated such that they must be used

---

# Common patterns

- `Option` and `Result` are pervasive
- Patterns emerge and are added to the standard library

---

# Require successful values

import patternUnwrap from "rust:option-and-result/pattern-unwrap";

<Transclude src={patternUnwrap} focusOn="1+6" />

<Notes />

- Haven't talked about `panic!`, yet; it stops execution
- Taking an `Option` and unwrapping it like this is probably an antipattern

<Continue />

## Use `expect` or `unwrap`

import patternUnwrapRev1 from "rust:option-and-result/pattern-unwrap?rev=1";

<Transclude src={patternUnwrapRev1} focusOn="1+4" />

<Notes />

- Same methods exist on `Result`, will print the error value
- `unwrap` is the same, but doesn't take a string
- Note that we shadow the variable name

---

# Get the value or default

import patternDefault from "rust:option-and-result/pattern-default";

<Transclude src={patternDefault} focusOn="1+7" />

<Continue />

## Use `unwrap_or`

import patternDefaultRev1 from "rust:option-and-result/pattern-default?rev=1";

<Transclude src={patternDefaultRev1} focusOn="1+4" />

<Notes />

- Same method exists on `Option`

---

# Side note: Lazy evaluation

import patternDefaultLazy from "rust:option-and-result/pattern-default-lazy";

<Transclude src={patternDefaultLazy} />

<Continue />

import patternDefaultLazyStdout from "stdout:option-and-result/pattern-default-lazy";

<Transclude lang="compiler-output" src={patternDefaultLazyStdout} />

---

# Lazy variants

## Use `unwrap_or_else`

import patternDefaultLazyRev1 from "rust:option-and-result/pattern-default-lazy?rev=1";

<Transclude src={patternDefaultLazyRev1} focusOn="1+7" />

<Continue />

import patternDefaultLazyRev1Stdout from "stdout:option-and-result/pattern-default-lazy?rev=1";

<Transclude lang="compiler-output" src={patternDefaultLazyRev1Stdout} />

<Notes />

- You will see `_else` / `_then` / `_with`
- These suffixes are common patterns in Rust

---

# Side note: References

import patternDefaultReference from "rust:option-and-result/pattern-default-reference";

<Transclude src={patternDefaultReference} focusOn="1+8" />

<Continue />

import patternDefaultReferenceRev1 from "rust:option-and-result/pattern-default-reference?rev=1";

<Transclude src={patternDefaultReferenceRev1} focusOn="1+7" />

<Continue />

import patternDefaultReferenceRev1Stderr from "stderr:option-and-result/pattern-default-reference?rev=1";

<Transclude
  lang="compiler-error"
  src={patternDefaultReferenceRev1Stderr}
  focusOn="1+10"
/>

---

# Many methods consume the `Option` or `Result`

`unwrap_or` takes ownership of `self`

<Transclude
  lang="compiler-error"
  src={patternDefaultReferenceRev1Stderr}
  focusOn="12+5"
/>

<Continue />

We don't have ownership of the `Option`

<Transclude src={patternDefaultReferenceRev1} focusOn="3" emphasize="1[32+1]" />

---

# Converting references to values to values of references

## Use `as_ref`

import patternDefaultReferenceRev2 from "rust:option-and-result/pattern-default-reference?rev=2";

<Transclude
  src={patternDefaultReferenceRev2}
  focusOn="3+4"
  emphasize="2[21+9],2[41+1]"
/>

<Notes />

- We also changed this to have a reference to a literal `Score`

---

# Converting success values to different types

import patternMap from "rust:option-and-result/pattern-map";

<Transclude src={patternMap} focusOn="1+6" />

<Continue />

## Use `map`

import patternMapRev1 from "rust:option-and-result/pattern-map?rev=1";

<Transclude src={patternMapRev1} focusOn="1+3" />

<Continue />

## Or `?`

import patternMapRev2 from "rust:option-and-result/pattern-map?rev=2";

<Transclude src={patternMapRev2} focusOn="1+3" />

---

# Converting from `Result` to `Option`

import patternResultToOption from "rust:option-and-result/pattern-result-to-option";

<Transclude src={patternResultToOption} focusOn="1+6" />

<Continue />

## Use `ok`

import patternResultToOptionRev1 from "rust:option-and-result/pattern-result-to-option?rev=1";

<Transclude src={patternResultToOptionRev1} focusOn="1+3" />

---

# Converting from `Option` to `Result`

import patternOptionToResult from "rust:option-and-result/pattern-option-to-result";

<Transclude src={patternOptionToResult} focusOn="1+6" />

<Continue />

## Use `ok_or`

import patternOptionToResultRev1 from "rust:option-and-result/pattern-option-to-result?rev=1";

<Transclude src={patternOptionToResultRev1} focusOn="1+3" />

<Notes />

- There is also `ok_or_else`

---

# Exercise: Parsing environment variables with fallback

- `std::env::var` gets an environment variable or an error
- If environment variable `PORT_NEW` is set, use it, otherwise use `PORT_OLD`.
- Parse the string to a number.
  - If it's not set or unable to be parsed, use `8080` as a default.

## Bonus

- Use `match` instead of combinators (or vice versa)

---

# One answer

<Continue />

import exerciseEnvVar from "rust:option-and-result/exercise-env-var";

<Transclude src={exerciseEnvVar} />

---

# There's more!

- "memorize" what's available
- [`Option` docs](https://doc.rust-lang.org/std/option/enum.Option.html)
- [`Result` docs](https://doc.rust-lang.org/std/result/enum.Result.html)

---

# ... just monoids in the category of endofunctors

- You'll see these patterns across a number of different types
  - `Option` / `Result`
  - `Iterator`
  - `Future` / `Stream`

---

<SlideLayout use={Splash} />

# <ChangeDeck deck="overview">Return</ChangeDeck>
