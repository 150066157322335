import { Notes, Continue, SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import Splash from "./layout/Splash";
import TestPattern from "./TestPattern";
import Transclude from "./Transclude";

<SlideLayout use={TestPattern} />

---

<SlideLayout use={Splash} />

# Advanced types and traits

---

# Traits are Rust's primary interesting form of abstraction

- Functions provide abstraction, but are well-known
- Macros are a different kind of abstraction, but less common

---

# Common traits

- `Copy` / `Clone`
- `Debug` / `Display`
- `Iterator` / `IntoIterator`
- `Into` / `From` / `TryInto` / `TryFrom`
- `Fn` / `FnMut` / `FnOnce`
- `Add` / `Sub` / `Mul` / `Div` / etc.
- `Read` / `Write`

<Notes />

- Many more in the standard library
- Some of the standard library traits have special syntax (e.g. `Add`)
- Many crates define traits and implement them

---

# Basic structure of a trait

import basicStructureOfATrait from "rust:types/basic-structure-of-a-trait";

<Transclude src={basicStructureOfATrait} focusOn="1+17" />

---

# Basic structure of a trait

<Transclude src={basicStructureOfATrait} focusOn="1+17" emphasize="1" />

<Notes />

- Keyword `trait`
- Give it a name

---

# Basic structure of a trait

<Transclude src={basicStructureOfATrait} focusOn="1+17" emphasize="2" />

<Notes />

- Add methods and associated functions, just like an inherent implementation
- Does not need a body

---

# Basic structure of a trait

<Transclude src={basicStructureOfATrait} focusOn="1+17" emphasize="7[5+9]" />

<Notes />

- A block distinct from the trait and the type is used for implementation

---

# Basic structure of a trait

<Transclude src={basicStructureOfATrait} focusOn="1+17" emphasize="8,9,10" />

<Notes />

- Body of the implementation

---

# Basic structure of a trait

<Transclude src={basicStructureOfATrait} focusOn="1+17" emphasize="15" />

<Notes />

- Methods and associated functions can be called the same as inherent ones

---

# Why the separate / repetitive syntax?

<Continue />

Implement your trait for other crate's types

<Transclude src={basicStructureOfATrait} focusOn="18+5" emphasize="1[11+8]" />

<Notes />

- Can define traits for types you don't own

<Continue />

Sometimes, that's the reason for the trait

<Transclude src={basicStructureOfATrait} focusOn="24+7" />

<Notes />

- If that's the only purpose, known as an _extension trait_

---

# How far can we go?

import implDisplayForVecRev0 from "rust:types/impl-display-for-vec";
import implDisplayForVecRev0Error from "stderr:types/impl-display-for-vec";

<Transclude src={implDisplayForVecRev0} />

<Transclude
  src={implDisplayForVecRev0Error}
  lang="compiler-error"
  focusOn="1+12"
/>

---

# How far can we go?

import implDisplayForVecRev1 from "rust:types/impl-display-for-vec?rev=1";
import implDisplayForVecRev1Error from "stderr:types/impl-display-for-vec?rev=1";

<Transclude src={implDisplayForVecRev1} />

<Notes />

- What do you expect the output to be?

<Continue />

<Transclude
  src={implDisplayForVecRev1Error}
  lang="compiler-error"
  focusOn="1+11"
/>

---

# Orphan rules and coherence

- The crate must own either:
  - the type being defined
  - the trait being defined
- Prevents conflicting implementations between crates
- Prevents adding an implementation being a breaking change

<Notes />

- Pedantically, the restriction is more complicated, but that covers 99% of the cases

---

# Traits must be in scope to be used

import traitsInScopeRev0 from "rust:types/traits-in-scope";

<Transclude src={traitsInScopeRev0} />

---

# Traits must be in scope to be used

import traitsInScopeRev0Error from "stderr:types/traits-in-scope?rev=0";

<Transclude src={traitsInScopeRev0Error} lang="compiler-error" focusOn="1+14" />

---

# Traits must be in scope to be used

import traitsInScopeRev1 from "rust:types/traits-in-scope?rev=1";

<Transclude src={traitsInScopeRev1} emphasize="13" />

---

# Exercise: Write a trait for if something is "odd"

import traitForOdd from "rust:types/trait-for-odd";

- Define it for at least two types
- One type should be an integer of some flavor
- Code like this should run:

  <Transclude src={traitForOdd} focusOn="18+2" />

---

# One potential solution

<Continue />

<Transclude src={traitForOdd} />

---

# Generics

- Adding methods to types is nice
  - not the real power of traits
- Create code that works on multiple types

---

# Basic structure of generic functions

import basicGenericStructure from "rust:types/basic-generic-structure";

<Transclude src={basicGenericStructure} focusOn="1+3" />

---

# Basic structure of generic functions

<Transclude src={basicGenericStructure} focusOn="1+3" emphasize="1[13+3]" />

<Notes />

- Declare the generic type
- Done to clearly disambiguate any real type with the same name

---

# Basic structure of generic functions

<Transclude
  src={basicGenericStructure}
  focusOn="1+3"
  emphasize="1[24+1],1[30+1]"
/>

<Notes />

- Can then use that generic type as an argument or return type

---

# Basic structure of generic types

<Transclude src={basicGenericStructure} focusOn="5+7" />

---

# Basic structure of generic types

<Transclude src={basicGenericStructure} focusOn="5+7" emphasize="1[15+3]" />

<Notes />

- The struct declares the generic type (same reasons)

---

# Basic structure of generic types

<Transclude src={basicGenericStructure} focusOn="5+7" emphasize="1[19+1]" />

<Notes />

- Can make use of the generic type

---

# Basic structure of generic types

<Transclude src={basicGenericStructure} focusOn="5+7" emphasize="3[4+3]" />

<Notes />

- Implementations also declare the generic type

---

# Basic structure of generic types

<Transclude src={basicGenericStructure} focusOn="5+7" emphasize="3[16+3]" />

---

# Basic structure of generic types

<Transclude src={basicGenericStructure} focusOn="5+7" emphasize="4[27+1]" />

---

# What can we do with a generic?

import traitBoundsRev0 from "rust:types/trait-bounds";
import traitBoundsRev0Error from "stderr:types/trait-bounds";

<Transclude src={traitBoundsRev0} focusOn="1+3" />

<Continue />

<Transclude src={traitBoundsRev0Error} lang="compiler-error" focusOn="1+15" />

---

# Trait bounds

- Fewer restrictions
  - more types are likely to be allowed
  - fewer actions can be taken on a value
- More restrictions
  - fewer types are likely to be allowed
  - more actions can be taken on a value

---

# Trait bounds

import traitBoundsRev1 from "rust:types/trait-bounds?rev=1";
import traitBoundsRev2 from "rust:types/trait-bounds?rev=2";

### Inline

<Transclude src={traitBoundsRev1} focusOn="1+5" emphasize="3[15+9]" />

### Where clause

<Transclude src={traitBoundsRev2} focusOn="1+8" emphasize="4,5" />

<Notes />

- I advocate for the `where` clause in almost every case.
- I find:
  - it's better for scanning the code
  - less version control churn
  - more complicated conditions

---

# Multiple trait bounds

import traitBoundsRev3 from "rust:types/trait-bounds?rev=3";
import traitBoundsRev4 from "rust:types/trait-bounds?rev=4";

### Inline

<Transclude src={traitBoundsRev3} focusOn="1+5" emphasize="3[17+23]" />

### Where clause

<Transclude src={traitBoundsRev4} focusOn="1+6" emphasize="5,6" />

<Notes />

- Where clause can also have multiple lines for the same generic

---

# Failing to meet trait bounds

import traitBoundsRev4Error from "stderr:types/trait-bounds?rev=4";

<Transclude src={traitBoundsRev4} focusOn="11+3" />

<Transclude src={traitBoundsRev4Error} lang="compiler-error" focusOn="1+20" />

<Notes />

- Error is provided at the location of use, not somewhere deep in a callstack

---

# Exercise: Write a function that makes two copies of a value

import twoCopies from "rust:types/two-copies";

- Takes a single value
- Returns two values in a tuple
- Code like this should run:

  <Transclude src={twoCopies} focusOn="9+4" />

## Bonus

- Does it actually need to make two _copies_?

---

# One potential solution

<Continue />

<Transclude src={twoCopies} />

---

# Abstracting across types in abstract functions

import associatedTypesRev0 from "rust:types/associated-types";

<Transclude src={associatedTypesRev0} focusOn="1+21" />

<Continue />

<Transclude src={associatedTypesRev0} focusOn="23+3" />

<Notes />

- Could use dynamic dispatch via trait object or enum

---

# Associated types

- Specify types that are relevant to the trait
- The implementer of the trait chooses the type

---

# Basic structure of associated types

import associatedTypesRev1 from "rust:types/associated-types?rev=1";

<Transclude src={associatedTypesRev1} focusOn="11+21" />

---

# Basic structure of associated types

<Transclude src={associatedTypesRev1} focusOn="11+21" emphasize="2,8,16" />

---

# Basic structure of associated types

<Transclude src={associatedTypesRev1} focusOn="11+21" emphasize="4,10,18" />

---

# Using associated types

<Transclude src={associatedTypesRev1} focusOn="33+6" />

<Notes />

- Works fine, right?

---

# Using associated types

import associatedTypesRev2 from "rust:types/associated-types?rev=2";
import associatedTypesRev2Error from "stderr:types/associated-types?rev=2";

<Transclude src={associatedTypesRev2} focusOn="33+7" />

<Continue />

<Transclude
  src={associatedTypesRev2Error}
  lang="compiler-error"
  focusOn="1+15"
/>

---

# Trait bounds can be applied to associated types as well

import associatedTypesRev3 from "rust:types/associated-types?rev=3";

<Transclude src={associatedTypesRev3} focusOn="33+10" />

---

# Trait bounds can be applied to associated types as well

<Transclude src={associatedTypesRev3} focusOn="33+10" emphasize="6" />

---

# What if we wanted a specific associated type?

import associatedTypesRev4 from "rust:types/associated-types?rev=4";
import associatedTypesRev4Error from "stderr:types/associated-types?rev=4";

<Transclude src={associatedTypesRev4} focusOn="33+7" />

---

# What if we wanted a specific associated type?

<Transclude
  src={associatedTypesRev4Error}
  lang="compiler-error"
  focusOn="1+19"
/>

---

# Specific associated types

import associatedTypesRev5 from "rust:types/associated-types?rev=5";

<Transclude src={associatedTypesRev5} focusOn="33+7" emphasize="3[12+20]" />

---

# Exercise: Write two functions that take iterators

import takingIteratorsRev0 from "rust:types/taking-iterators";

- One function takes an iterator of `i32`s and adds them together
- One function takes an iterator of anything that can be turned `Into` a `String` and joins them into one string
- Code like this should run:

  <Transclude src={takingIteratorsRev0} focusOn="25+7" />

## Bonus

- Can you remove the `into_iter()` call?

<Notes />

- Does anyone ask about the empty iterator case?

---

# One potential solution

<Continue />

<Transclude src={takingIteratorsRev0} focusOn="1+23" />

---

# One potential solution

import takingIteratorsRev1 from "rust:types/taking-iterators?rev=1";

<Transclude src={takingIteratorsRev1} focusOn="1+15" />

---

# Generic traits

- Traits can also have generic types

---

# Using generic traits

import genericTraits from "rust:types/generic-traits";

<Transclude src={genericTraits} focusOn="1+20" />

<Notes />

- Simplified version of actual trait

---

# Using generic traits

<Transclude src={genericTraits} focusOn="1+20" emphasize="1[11+3]" />

---

# Using generic traits

<Transclude
  src={genericTraits}
  focusOn="1+20"
  emphasize="10[11+6],11[25+6],16[11+2],17[25+2]"
/>

---

# Choosing a trait with generics vs. associated types

- Trait methods are selected by:
  - the method name
  - the implementing type
  - any generic types in play
- A generic trait can be implemented many times for a type
- Generic traits can be more cumbersome to use
- When in doubt, start with an associated type

---

# Generic associated types

import genericAssociatedTypes from "rust:types/generic-associated-types";

<Transclude
  src={genericAssociatedTypes}
  focusOn="1+7"
  emphasize="2[14+2],6[12+2],6[17+2],6[51+2]"
/>

<Notes />

- Allows associated types to themselves be generic
- Allows tying the lifetime of `Item` to `self`

---

# Generic associated types

<Transclude
  src={genericAssociatedTypes}
  focusOn="9+25"
  emphasize="7[14+2],7[21+2],9[44+2]"
/>

<Notes />

- Using lifetime elision here

---

# More tricks that traits can do

- Default functions
- Supertraits
- Associated constants
- Default type parameters

---

# Default functions

- A trait can provide a default implementation of a function
- Implementors of the trait can provide a better implementation
- The function should do the same thing

---

# Default functions

import defaultFunctions from "rust:types/default-functions";

<Transclude src={defaultFunctions} focusOn="1+28" />

---

# Default functions

<Transclude src={defaultFunctions} focusOn="1+28" emphasize="4,5,6" />

---

# Default functions

<Transclude src={defaultFunctions} focusOn="1+28" emphasize="11,12,13,14,15" />

---

# Default functions

<Transclude src={defaultFunctions} focusOn="1+28" emphasize="24,25,26" />

<Notes />

- There's no equivalent of `super()`, this is not inheritance

---

# Supertraits

- Implementing the trait requires implementing supertraits
- Can use supertrait's functionality in trait implementation

---

# Supertraits

import supertraits from "rust:types/supertraits";

<Transclude src={supertraits} focusOn="1+18" />

---

# Supertraits

<Transclude src={supertraits} focusOn="1+18" emphasize="3[12+7]" />

---

# Supertraits

<Transclude src={supertraits} focusOn="1+18" emphasize="7" />

---

# Associated constants

- Constants associated with the implementation of the trait
- Guaranteed to be evaluated at compile-time
- Type of the constant can be an associated type or generic

---

# Associated constants

import associatedConstants from "rust:types/associated-constants";

<Transclude src={associatedConstants} focusOn="1+16" />

---

# Associated constants

<Transclude src={associatedConstants} focusOn="1+16" emphasize="2" />

---

# Associated constants

<Transclude src={associatedConstants} focusOn="1+16" emphasize="10" />

---

# Default type parameters

- You need the flexibility of a generic type
  - but there's a frequently used type
- Also available when defining a type or a type alias

---

# Default type parameters

import defaultTypeParams from "rust:types/default-type-params";

<Transclude src={defaultTypeParams} focusOn="1+12" />

---

# Default type parameters

<Transclude src={defaultTypeParams} focusOn="1+12" emphasize="1[16+7]" />

---

# Default type parameters

<Transclude src={defaultTypeParams} focusOn="1+12" emphasize="7[5+9]" />

---

# Default type parameters

<Transclude src={defaultTypeParams} focusOn="1+12" emphasize="8[37+3]" />

---

# Exercise: Kitchen sink features of traits

import kitchenSink from "rust:types/kitchen-sink";

- Create a trait for snacks that:
  - requires types to be printed via `{:?}`
  - requires types to be comparable to each other
- The trait has a method that "eats"
  - both snacks by default, if they are equal
  - only the first `Cookie`, if they are **not** equal
- Code like this should run:

  <Transclude src={kitchenSink} focusOn="26+3" />

---

# One potential solution

<Continue />

<Transclude src={kitchenSink} />

---

# Generics have no runtime overhead

- At compile time, effectively copy code for each unique type
- The fancy term is _monomorphization_
- Good example of _zero cost abstraction_
  - a human could not have written the same code better

<Notes />

- Can lead to code bloat
- Optimizer may combine code that is identical

---

# Monomorphization (before)

import monomorphizationRev0 from "rust:types/monomorphization";

<Transclude src={monomorphizationRev0} />

---

# Monomorphization (after)

import monomorphizationRev1 from "rust:types/monomorphization?rev=1";

<Transclude src={monomorphizationRev1} />

<Notes />

- Not actual names or code generated

---

# Trait objects

- Allow for dynamic dispatch
- Always behind a pointer of some kind
  - `&`, `Box`, `Rc`, `Arc`, etc.
- Only one implementation of the code created

---

# Trait objects

import traitObjectsRev0 from "rust:types/trait-objects";

<Transclude src={traitObjectsRev0} />

---

# Trait objects

<Transclude src={traitObjectsRev0} emphasize="17[15+3]" />

<Notes />

- Required starting in 2021

---

# Trait objects

<Transclude src={traitObjectsRev0} emphasize="22[10+5],23[10+5]" />

<Notes />

- Take a reference to the concrete

---

# Heterogenous collections

import traitObjectsRev1 from "rust:types/trait-objects?rev=1";
import traitObjectsRev1Error from "stderr:types/trait-objects?rev=1";

<Transclude src={traitObjectsRev1} focusOn="21+6" />

<Continue />

<Transclude src={traitObjectsRev1Error} lang="compiler-error" focusOn="1+5" />

---

# Heterogenous collections

import traitObjectsRev2 from "rust:types/trait-objects?rev=2";

<Transclude src={traitObjectsRev2} focusOn="21+7" />

---

# Heterogenous collections

<Transclude src={traitObjectsRev2} focusOn="21+7" emphasize="2[14+17]" />

<Notes />

- Explcitly type the vector as of trait objects
- Needed to distinguish from vector of `Box<Doll>` or `Box<Drum>`

---

# Heterogenous collections

<Transclude
  src={traitObjectsRev2}
  focusOn="21+7"
  emphasize="2[39+9],2[52+1],2[55+9],2[68+1]"
/>

<Notes />

- Box each value in the collection

---

# Heterogenous collections

<Transclude src={traitObjectsRev2} focusOn="21+7" emphasize="4[14+3]" />

<Notes />

- A bit of syntax trickery since `Box<dyn Trait>` doesn't implement `Trait`
- Could avoid this by implementing it
- Could talk about `Deref`

---

# Trait object implementation

- A trait object is a _fat pointer_ (Two pointers)
  - Pointer to the data
  - Pointer to the method implementations (the _vtable_)

<Notes />

- Most of the time, you don't need to worry about this.

---

# Object safety

- Not all traits can be made into trait objects
- Condensed version of the rules:
  - Functions must have a `self` parameter (a.k.a. be methods)
  - Methods may not use `Self` as an argument or return type
  - Methods may not use generics

<Notes />

- The reason that these all fail is because we are unable to create the vtable

---

# Object safety demonstrated

import objectSafety from "rust:types/object-safety";

<Transclude src={objectSafety} focusOn="1+7" />

<Continue />

import objectSafetyError from "stderr:types/object-safety";

<Transclude src={objectSafetyError} lang="compiler-error" focusOn="1+15" />

<Notes />

- `Clone` and `PartialEq` are common ways to hit this

---

# Exercise: Math with trait objects

import traitObjectMath from "rust:types/trait-object-math";

- Create a trait with a method that takes and returns an `i32`
- Create two implementations of the trait
- Create a function that takes a slice of trait objects
- Code like this should run:

  <Transclude src={traitObjectMath} focusOn="26" />

## Bonus

- Replace the function with another implementation of the trait

---

# One potential solution

<Continue />

<Transclude src={traitObjectMath} />

---

# Returning a value that implements a trait

- Returning a value that doesn't specify the concrete type
  - don't want to name it
  - cannot name it

---

# Owned trait objects

import ownedTraitObjects from "rust:types/owned-trait-objects";

<Transclude src={ownedTraitObjects} focusOn="1+15" />

---

# Owned trait objects

<Transclude src={ownedTraitObjects} focusOn="1+15" emphasize="7[44+15]" />

---

# Owned trait objects

<Transclude
  src={ownedTraitObjects}
  focusOn="1+15"
  emphasize="9[24+9],9[36+1],10[25+9],10[42+1],11[25+9],11[40+1],12[26+9],12[39+1]"
/>

<Notes />

- Frequently it's `Box`, but other owning types are valid too (`Rc`, `Arc`)

---

# `impl Trait`

import implTrait from "rust:types/impl-trait";

<Transclude src={implTrait} focusOn="1+16" />

---

# `impl Trait`

<Transclude src={implTrait} focusOn="1+16" emphasize="13[15+11]" />

<Notes />

- Note the keyword `impl` instead of `dyn`
- This is a reason it's recommended to use the `dyn` keyword

---

# `impl Trait`

<Transclude src={implTrait} focusOn="1+16" emphasize="14[4+3]" />

<Notes />

- No need for heap allocation
- The compiler knows there's a concrete type, it's just not exposed

---

# The type who must not be named

import voldemortTypes from "rust:types/voldemort-types";

<Transclude src={voldemortTypes} focusOn="2+2" />

<Notes />

- But what type is `some_closure`?

<Continue />

import voldemortTypesRev1 from "rust:types/voldemort-types?rev=1";

<Transclude src={voldemortTypesRev1} focusOn="2+3" />

<Notes />

- As a hint, this compiles

---

# Peeking under the covers

import voldemortTypesRev2 from "rust:types/voldemort-types?rev=2";
import voldemortTypesRev2Error from "stderr:types/voldemort-types?rev=2";

<Transclude src={voldemortTypesRev2} focusOn="2+1" emphasize="1[16+4]" />

---

# Peeking under the covers

<Transclude src={voldemortTypesRev2} focusOn="2+1" />
<Transclude
  src={voldemortTypesRev2Error}
  lang="compiler-error"
  focusOn="1+10"
/>

---

# Peeking under the covers

<Transclude src={voldemortTypesRev2} focusOn="2+1" />
<Transclude
  src={voldemortTypesRev2Error}
  lang="compiler-error"
  focusOn="1+10"
  emphasize="10"
/>

<Notes />

- This type is made by the compiler and cannot be named by the programmer

---

# `impl Trait`

- Allows returning a concrete type without saying what that type is
- Value does not require heap allocation
- Currently limited to free functions and inherent methods
  - cannot be used in traits

---

# `impl Trait` can return closures

import implTraitReturnRev0 from "rust:types/impl-trait-return";

<Transclude src={implTraitReturnRev0} />

---

# `impl Trait` can return iterators containing closures

import implTraitReturnRev1 from "rust:types/impl-trait-return?rev=1";

<Transclude src={implTraitReturnRev1} />

<Notes />

- Not limited to iterators, those are just very common

---

# `impl Trait` as an argument

import implTraitArgRev0 from "rust:types/impl-trait-arg";

<Transclude src={implTraitArgRev0} focusOn="1+5" />

<Continue />

import implTraitArgRev1 from "rust:types/impl-trait-arg?rev=1";

<Transclude src={implTraitArgRev1} focusOn="1+5" />

<Continue />

import implTraitArgRev2 from "rust:types/impl-trait-arg?rev=2";

<Transclude src={implTraitArgRev2} focusOn="1+8" />

<Notes />

- All of these are the same
- Less ceremony to more, `where` clause more powerful

---

# Exercise: `impl Trait`

import implTraitIterators from "rust:types/impl-trait-iterators";

- Create three functions manipulating iterators of `i32`:
  - generate an iterator
  - take an iterator, double every value, return the iterator
  - take an iterator and print each value
- Code like this should run:

  <Transclude src={implTraitIterators} focusOn="16" />

# Bonus

- Generate an infinite iterator
  - use a method from `Iterator` to limit it

---

# One potential solution

<Continue />

<Transclude src={implTraitIterators} />

---

# Rust has three kinds of generics

import kindsOfGenerics from "rust:types/kinds-of-generics";

- Lifetimes

  <Transclude src={kindsOfGenerics} focusOn="1" />

<Continue />

- Types

  <Transclude src={kindsOfGenerics} focusOn="3" />

<Continue />

- Constants

  <Transclude src={kindsOfGenerics} focusOn="5" />

<Notes />

- Constants are new in Rust 1.51!

---

# Const generics

import constGenerics from "rust:types/const-generics";

<Transclude src={constGenerics} />

---

# Advanced const generics - defaults

import constGenericsDefaults from "rust:types/const-generics-defaults";

<Transclude src={constGenericsDefaults} focusOn="1+1" />

---

# Const generics

- Largely used for generalizing over arrays
- Can be used with integers, `char`, `bool`

<Notes />

- Old versions of Rust only implemented traits for array lengths <= 32
- Was done via macro; const generics saved space and compile time
- Doesn't support custom types or expressions

---

# Lifetimes and traits

import lifetimesAndTraitsRev0 from "rust:types/lifetimes-and-traits";

<Transclude src={lifetimesAndTraitsRev0} focusOn="1+10" />

---

# Lifetimes and traits

import lifetimesAndTraitsRev0Error from "stderr:types/lifetimes-and-traits";

<Transclude
  src={lifetimesAndTraitsRev0Error}
  lang="compiler-error"
  focusOn="1+17"
/>

---

# Lifetimes and traits

### Lifetimes elided

<Transclude src={lifetimesAndTraitsRev0} focusOn="6+5" />

<Continue />

### Lifetimes explicit

import lifetimesAndTraitsRev1 from "rust:types/lifetimes-and-traits?rev=1";

<Transclude src={lifetimesAndTraitsRev1} focusOn="6+5" />

---

# Lifetimes and traits

### Lifetimes elided

<Transclude src={lifetimesAndTraitsRev0} focusOn="6+5" />

### Lifetimes explicit

<Transclude
  src={lifetimesAndTraitsRev1}
  focusOn="6+5"
  emphasize="2[14+2],2[19+2],2[58+7]"
/>

---

# Lifetimes and traits

### Lifetimes elided

<Transclude src={lifetimesAndTraitsRev0} focusOn="6+5" />

### Lifetimes explicit

<Transclude src={lifetimesAndTraitsRev1} focusOn="6+5" emphasize="3[8+18]" />

---

# Lifetimes and traits

### Lifetimes elided

<Transclude src={lifetimesAndTraitsRev0} focusOn="6+5" />

### Lifetimes explicit

<Transclude src={lifetimesAndTraitsRev1} focusOn="6+5" />

### Actual types

import lifetimesAndTraitsRev2 from "rust:types/lifetimes-and-traits?rev=2";

<Transclude src={lifetimesAndTraitsRev2} focusOn="6+5" />

---

# Lifetimes and traits

### Lifetimes elided

<Transclude src={lifetimesAndTraitsRev0} focusOn="6+5" />

### Lifetimes explicit

<Transclude src={lifetimesAndTraitsRev1} focusOn="6+5" emphasize="2[58+7]" />

### Actual types

<Transclude src={lifetimesAndTraitsRev2} focusOn="6+5" emphasize="2[66+2]" />

---

# Indicating lifetimes

### Explicit lifetime

import lifetimesAndTraitsRev3 from "rust:types/lifetimes-and-traits?rev=3";

<Transclude src={lifetimesAndTraitsRev3} focusOn="6+5" />

<Notes />

- Clearly shows all the pieces

<Continue />

### Anonymous lifetime

import lifetimesAndTraitsRev4 from "rust:types/lifetimes-and-traits?rev=4";

<Transclude src={lifetimesAndTraitsRev4} focusOn="6+5" />

<Notes />

- There _is_ a lifetime, but we don't care to name it

---

# Higher-ranked trait bounds

import higherRankedTraitBoundsRev0 from "rust:types/higher-ranked-trait-bounds";

<Transclude src={higherRankedTraitBoundsRev0} focusOn="1+25" />

---

# Higher-ranked trait bounds

<Transclude src={higherRankedTraitBoundsRev0} focusOn="1+25" emphasize="3" />

---

# Higher-ranked trait bounds

<Transclude src={higherRankedTraitBoundsRev0} focusOn="1+25" emphasize="7" />

---

# Higher-ranked trait bounds

<Transclude
  src={higherRankedTraitBoundsRev0}
  focusOn="1+25"
  emphasize="12,13,14,15,16,17,21,22,23,24,25"
/>

---

# Higher-ranked trait bounds

<Transclude src={higherRankedTraitBoundsRev0} focusOn="27+7" />

<Continue />

import higherRankedTraitBoundsRev0Error from "stderr:types/higher-ranked-trait-bounds";

<Transclude
  src={higherRankedTraitBoundsRev0Error}
  lang="compiler-error"
  focusOn="1+14"
/>

---

# Higher-ranked trait bounds

import higherRankedTraitBoundsRev1 from "rust:types/higher-ranked-trait-bounds?rev=1";

<Transclude src={higherRankedTraitBoundsRev1} focusOn="27+8" emphasize="4" />

<Continue />

import higherRankedTraitBoundsRev1Error from "stderr:types/higher-ranked-trait-bounds?rev=1";

<Transclude
  src={higherRankedTraitBoundsRev1Error}
  lang="compiler-error"
  focusOn="1+6"
/>

<Notes />

- Only focusing on the first error

---

# Higher-ranked trait bounds

import higherRankedTraitBoundsRev2 from "rust:types/higher-ranked-trait-bounds?rev=2";

<Transclude
  src={higherRankedTraitBoundsRev2}
  focusOn="27+8"
  emphasize="1[24+2],4[5+2]"
/>

<Continue />

import higherRankedTraitBoundsRev2Error from "stderr:types/higher-ranked-trait-bounds?rev=2";

<Transclude
  src={higherRankedTraitBoundsRev2Error}
  lang="compiler-error"
  focusOn="1+9"
/>

---

# Higher-ranked trait bounds

import higherRankedTraitBoundsRev3 from "rust:types/higher-ranked-trait-bounds?rev=3";

<Transclude src={higherRankedTraitBoundsRev3} focusOn="27+10" emphasize="5,6" />

<Notes />

- Two cycles later...

<Continue />

import higherRankedTraitBoundsRev3Error from "stderr:types/higher-ranked-trait-bounds?rev=3";

<Transclude
  src={higherRankedTraitBoundsRev3Error}
  focusOn="1+14"
  lang="compiler-error"
/>

---

# Higher-ranked trait bounds

<Transclude
  src={higherRankedTraitBoundsRev1Error}
  lang="compiler-error"
  focusOn="7+4"
/>

<Continue />

import higherRankedTraitBoundsRev4 from "rust:types/higher-ranked-trait-bounds?rev=4";

<Transclude
  src={higherRankedTraitBoundsRev4}
  focusOn="27+8"
  emphasize="4[4+7]"
/>

---

# Future work

## Being worked on

- Specialization
- Advanced const generics
- Enhanced `impl Trait` / existential types
- Generic associated types

## Beyond

- Auto traits

<Notes />

- Warning: may or may not happen in 2022
- Warning: may or may not look like the code shown here

---

# Specialization

import specializationRev0 from "rust:types/specialization";

<Transclude src={specializationRev0} focusOn="1+12" />

<Notes />

- Implement the ability to get the size of any type that can be made into an iterator

<Continue />

<Transclude src={specializationRev0} focusOn="14+5" />

<Notes />

- But we want faster code for a `Vec`

---

# Specialization

import specializationError from "stderr:types/specialization";

<Transclude src={specializationError} lang="compiler-error" focusOn="1+11" />

---

# Specialization

import specializationRev1 from "rust:types/specialization?rev=1";

<Transclude src={specializationRev1} focusOn="1+20" emphasize="1" />

---

# Specialization

<Transclude src={specializationRev1} focusOn="1+20" emphasize="11[4+7]" />

<Notes />

- This works!
- Current implementation has known soundness holes.
- Future work to allow specializing on traits, not just types

---

# Advanced const generics - user types

import constGenericsUserTypes from "rust:types/const-generics-user-types";

<Transclude src={constGenericsUserTypes} focusOn="1+9" />

<Continue />

import constGenericsUserTypesError from "stderr:types/const-generics-user-types";

<Transclude
  src={constGenericsUserTypesError}
  lang="compiler-error"
  focusOn="1+8"
/>

---

# Advanced const generics - user types

import constGenericsUserTypesRev1 from "rust:types/const-generics-user-types?rev=1";

<Transclude src={constGenericsUserTypesRev1} focusOn="1+11" emphasize="1,3" />

---

# Advanced const generics - expressions

import constGenericsExpressions from "rust:types/const-generics-expressions";

<Transclude src={constGenericsExpressions} focusOn="1+3" />

<Continue />

import constGenericsExpressionsError from "stderr:types/const-generics-expressions";

<Transclude
  src={constGenericsExpressionsError}
  lang="compiler-error"
  focusOn="1+10"
/>

---

# Advanced const generics - expressions

import constGenericsExpressionsRev1 from "rust:types/const-generics-expressions?rev=1";

<Transclude src={constGenericsExpressionsRev1} focusOn="1+5" emphasize="1" />

---

# Enhanced `impl Trait` / existential types

import typeAliasImplTrait from "rust:types/type-alias-impl-trait";

<Transclude src={typeAliasImplTrait} focusOn="1+15" />

<Continue />

import typeAliasImplTraitError from "stderr:types/type-alias-impl-trait";

<Transclude
  src={typeAliasImplTraitError}
  lang="compiler-error"
  focusOn="1+10"
/>

---

# Enhanced `impl Trait` / existential types

import typeAliasImplTraitRev1 from "rust:types/type-alias-impl-trait?rev=1";

<Transclude src={typeAliasImplTraitRev1} focusOn="1+17" emphasize="1" />

<Notes />

- This works!
- Waiting on syntax agreement?
- Needs GATs

---

# Auto traits

import autoTraits from "rust:types/auto-traits";

<Transclude src={autoTraits} />

<Continue />

import autoTraitsError from "stderr:types/auto-traits";

<Transclude src={autoTraitsError} lang="compiler-error" focusOn="1+11" />

---

# Auto traits

import autoTraitsRev1 from "rust:types/auto-traits?rev=1";

<Transclude src={autoTraitsRev1} emphasize="1" />

---

# Auto traits

<Transclude src={autoTraitsRev1} emphasize="3[0+4]" />

---

# Auto traits

import autoTraitsRev1Error from "stderr:types/auto-traits?rev=1";

<Transclude src={autoTraitsRev1Error} lang="compiler-error" focusOn="1+13" />

---

# Auto traits

<Transclude
  src={autoTraitsRev1Error}
  lang="compiler-error"
  focusOn="15+10,29,34,39,45+10"
/>

---

<SlideLayout use={Splash} />

# <ChangeDeck deck="overview">Return</ChangeDeck>
