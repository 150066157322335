import * as styles from "./Quote.module.css";

interface QuoteProps {
  caption: string;
  href?: string;
}

const Quote: React.FC<QuoteProps> = ({ children, caption, href }) => {
  const captionContent = href ? <a href={href}>{caption}</a> : caption;

  return (
    <figure className={styles.container}>
      <blockquote className={styles.quote}>{children}</blockquote>
      <figcaption className={styles.caption}>{captionContent}</figcaption>
    </figure>
  );
};

export default Quote;
