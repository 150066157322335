.outer {
  composes: -center from "../util.module.css";
  composes: -expand from "../util.module.css";
}

.container {
  composes: -expand from "../util.module.css";
  display: grid;
  align-content: center;
  justify-content: center;
  background-color: white;
  font-size: 3rem;
  text-align: center;
}
