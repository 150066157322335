import { startAppListening } from "./listenerMiddleware";

const buildUrlMiddleware = (window: Window) => {
  startAppListening({
    predicate: (_action, currentState, previousState) => {
      const slideIndex =
        currentState.deck.slideIndex != previousState.deck.slideIndex;
      const deck = currentState.route.deck != previousState.route.deck;

      return slideIndex || deck;
    },
    effect: async (_action, listenerApi) => {
      const state = listenerApi.getState();
      const page = state.deck.slideIndex;
      const deck = state.route.deck;

      const url = new URL(window.location.toString());
      url.searchParams.set("page", page.toString());
      url.searchParams.set("deck", deck.toString());

      window.history.pushState(null, "", url.toString());
    },
  });
};

export default buildUrlMiddleware;
