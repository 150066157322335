import React, { useEffect } from "react";
import { Provider } from "react-redux";

import Deck from "./Deck";
import ErrorBoundary from "./ErrorBoundary";
import { Store } from "./app/store";

import OverviewMdx from "./overview.mdx";
import IntroMdx from "./intro.mdx";
import OwnershipMdx from "./ownership.mdx";
import OptionAndResultMdx from "./option-and-result.mdx";
import ErrorHandlingMdx from "./error-handling.mdx";
import IteratorsMdx from "./iterators.mdx";
import TypesMdx from "./types.mdx";
import ConcurrencyMdx from "./concurrency.mdx";
import AsyncMdx from "./async.mdx";
import FromDynamicLanguagesMdx from "./from-dynamic-languages.mdx";
import ReliableCodeUsingTypesMdx from "./reliable-code-using-types.mdx";
import TestingMdx from "./testing.mdx";
import StringsVsBytesMdx from "./strings-vs-bytes.mdx";
import MacrosMdx from "./macros.mdx";
import FfiMdx from "./ffi.mdx";
import EmbeddedMdx from "./embedded.mdx";
import { useAppSelector } from "./app/hooks";

interface AppProps {
  store: Store;
}

const HtmlStyler = () => {
  const fontSize = useAppSelector((s) => s.window.fontSize);

  useEffect(() => {
    window.document.documentElement.style.fontSize = `${fontSize}%`;
  }, [fontSize]);

  return null;
};

const DECKS = {
  overview: OverviewMdx,
  intro: IntroMdx,
  ownership: OwnershipMdx,
  "option-and-result": OptionAndResultMdx,
  "error-handling": ErrorHandlingMdx,
  iterators: IteratorsMdx,
  types: TypesMdx,
  concurrency: ConcurrencyMdx,
  async: AsyncMdx,
  "from-dynamic-languages": FromDynamicLanguagesMdx,
  "reliable-code-using-types": ReliableCodeUsingTypesMdx,
  testing: TestingMdx,
  "strings-vs-bytes": StringsVsBytesMdx,
  macros: MacrosMdx,
  ffi: FfiMdx,
  embedded: EmbeddedMdx,
};

const DeckSwitcher: React.FC = () => {
  const deck = useAppSelector((state) => state.route.deck);
  const Mdx = DECKS[deck];

  return <Deck MdxDocument={Mdx} />;
};

const App: React.FC<AppProps> = ({ store }) => (
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <HtmlStyler />
        <DeckSwitcher />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

export default App;
